import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'authReducer',
    initialState: {
        user: null,
        loggedIn: false 
    },
    reducers: {
        login: (state,action) => {
            state.user = action.payload;
            state.loggedIn = true;
        },
        logout: (state) => {
            state.user = null;
            state.loggedIn = false;
        }
    }
})

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;