// src/components/Login.js
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "./login.css";
import logins from "../../data/logins.json";
import { login } from "../../redux/features/authSlice"
import { useSelector, useDispatch } from "react-redux";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  


  const handleLogin = () => {
    const user = logins.admins.find(
      (admin) => admin.email === email && admin.password === password
    );

    if (user) {
      toast.success("Login successful");
      dispatch(login(user));
    } else {
      toast.error("Login failed");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="title-state-jobs">The State Jobs</h2>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" onClick={handleLogin}>
          Login
        </Button>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default Login;
